@tailwind base;
@tailwind components;
@tailwind utilities;

/* Import Inter font from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

html,
body {
	padding: 0;
	margin: 0;
	font-family: 'Inter', sans-serif; /* Default to Inter */
	background-color: #f5f5f5;
	font-weight: normal;
}

/* styles/globals.css */

/* @font-face {
	font-family: 'Coutier-Bold';
	src: url('/fonts/coutier-bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
} */
